'use client';
import { setClientDeviceSecret } from '@truckmap/common';
import { truckMapConfig } from 'truckMapConfig';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useAuthUser } from './useAuthUser';

export default function useDeviceSecret() {
  const user = useAuthUser();
  const deviceSecret = Cookies.get(truckMapConfig.cookies.deviceSecret);
  const { id: userId = 'guest' } = user || {};

  const isIframe = window !== window.top;

  const cookieOptions =
    truckMapConfig.isTenstreet && isIframe ? { sameSite: 'None' as const, secure: true } : {};

  useEffect(() => {
    if (deviceSecret) {
      setClientDeviceSecret(deviceSecret);
      Cookies.set(truckMapConfig.cookies.deviceSecret, deviceSecret, cookieOptions);
    }
  }, [deviceSecret, userId]);

  return {
    ready: deviceSecret?.length,
    deviceSecret: deviceSecret
  };
}
